// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["D_2D2xeVM"];

const variantClassNames = {D_2D2xeVM: "framer-v-11cj4gn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, link, scroll, width, ...props}) => { return {...props, EfAaePe38: scroll ?? props.EfAaePe38, Gk05OvDBe: link ?? props.Gk05OvDBe, wFgSHINGx: image ?? props.wFgSHINGx ?? {src: new URL("assets/51tmuppudzJ243rvnL0IcQnTjko.svg", import.meta.url).href}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;image?: {src: string; srcSet?: string};scroll?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Gk05OvDBe, wFgSHINGx, EfAaePe38, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "D_2D2xeVM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1oqijh0 = activeVariantCallback(async (...args) => {
if (EfAaePe38) {
const res = await EfAaePe38(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YpDSs", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={Gk05OvDBe}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 150, intrinsicWidth: 150, pixelHeight: 150, pixelWidth: 150, sizes: "min(52px, 100vw)", ...toResponsiveImage(wFgSHINGx)}} className={`${cx("framer-11cj4gn", className)} framer-11puz5b`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"D_2D2xeVM"} onTap={onTap1oqijh0} ref={ref} style={{...style}}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YpDSs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YpDSs .framer-11puz5b { display: block; }", ".framer-YpDSs .framer-11cj4gn { cursor: pointer; height: 52px; overflow: visible; position: relative; text-decoration: none; width: 52px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 52
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Gk05OvDBe":"link","wFgSHINGx":"image","EfAaePe38":"scroll"}
 */
const FramerdC13tyvle: React.ComponentType<Props> = withCSS(Component, css, "framer-YpDSs") as typeof Component;
export default FramerdC13tyvle;

FramerdC13tyvle.displayName = "Mascot";

FramerdC13tyvle.defaultProps = {height: 52, width: 52};

addPropertyControls(FramerdC13tyvle, {Gk05OvDBe: {title: "Link", type: ControlType.Link}, wFgSHINGx: {__defaultAssetReference: "data:framer/asset-reference,51tmuppudzJ243rvnL0IcQnTjko.svg?originalFilename=AI_mascot_logo_main.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, EfAaePe38: {title: "Scroll", type: ControlType.EventHandler}} as any)

addFonts(FramerdC13tyvle, [])